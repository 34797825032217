enum SettingPageContents {
  GeneralSetting = 'General',
  Company = 'Company',
  Agents = 'Agents',
  Employees = 'Employees',
  Security = 'Employee Roles',
  Vehicles = 'Vehicles',
  Services = 'Service Items',
  Skills = 'Skills',
  Templates = 'Templates',
  PDFLayout = 'PDF Layout',
  AppLog = 'App Logs'
}

export default SettingPageContents;
